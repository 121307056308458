import React from 'react'

const ContactContainer = () => {
  return (
    <div className='contact-container'>
        <p>andrewsimonmiller [ at ] gmail [ dot ] com</p>
    </div>
  )
}

export default ContactContainer